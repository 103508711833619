import type { WProductView, WRegistryView } from '@zola/svc-web-api-ts-client';
import { useEffect } from 'react';

import { useAppDispatch } from '@/store';

import type { UserContext } from '@/actions/UserActions.type';
import { onIdentifyGuestUser } from '@/actions/UserActions';
import { PublicRegistryContext } from './PublicRegistryContext';

type PublicRegistryProviderProps = {
  registryKey: string;
  userContext: UserContext | null;
  registryOverview: WRegistryView;
  isOwnRegistry: boolean;
  giftCardData: WProductView | null;
};

export const PublicRegistryProvider: React.FC<PublicRegistryProviderProps> = (props) => {
  const { registryKey, userContext, registryOverview, isOwnRegistry, children, giftCardData } =
    props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userContext) {
      // Handle Guest users to public registries identification
      dispatch(onIdentifyGuestUser());
    }
  }, [userContext, dispatch]);

  return (
    <PublicRegistryContext.Provider
      value={{
        registryKey,
        registryOverview,
        isOwnRegistry,
        giftCardData,
      }}
    >
      {children}
    </PublicRegistryContext.Provider>
  );
};
